import RadioGroupComponent from '@wix/thunderbolt-elements/src/components/RadioGroup/viewer/RadioGroup';
import RadioGroupController from '@wix/thunderbolt-elements/src/components/RadioGroup/viewer/RadioGroup.controller';


const RadioGroup = {
  component: RadioGroupComponent,
  controller: RadioGroupController
};


export const components = {
  ['RadioGroup']: RadioGroup
};

